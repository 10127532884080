import { FormEvent, useCallback, useEffect, useMemo } from "react";
import {
  PaymentElement,
  ExpressCheckoutElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Spinner from "@components/Spinner";
import clsx from "clsx";
import { useT } from "@utils/translation";
import { StripeElementLocale } from "@stripe/stripe-js";
import Button from "@components/Button";
import ChargingStartingIndicator from "./ChargingStartingIndicator";
import { toast } from "@components/toasts/state";
import { useStation } from "../../Station/state";
import { useChargingProcess } from "../state";
import { api } from "@utils/api";
import EmailInput from "./EmailInput";
import AmountInput from "./AmountInput";
import { StartChargingResult } from "./types";
import { parseClientError } from "@utils/errors";

export default function PayementForm() {
  const { i18n, t } = useT();
  const stripe = useStripe();
  const elements = useElements();

  const setChargingProcessState = useChargingProcess(
    (s) => s.setChargingProcessState
  );
  const amount = useChargingProcess((s) => s.amount);
  const email = useChargingProcess((s) => s.email);
  const paymentIntentId = useChargingProcess((s) => s.paymentIntentId);
  const stripeClientSecret = useChargingProcess((s) => s.stripeClientSecret);
  const startChargingRequested = useChargingProcess(
    (s) => s.hasRequestedStartCharging
  );
  const shouldStartCharging = useChargingProcess((s) => s.shouldStartCharging);
  const startCharging = useChargingProcess((s) => s.startCharging);

  const connector = useStation((s) => s.connector);
  const isFree = Boolean(connector?.priceInfo.isFree);

  const isDisabled = useMemo(
    () => (!stripe || !elements || !email) && !isFree,
    [stripe, elements, email, isFree]
  );

  const onSubmitForm = async (e?: FormEvent) => {
    e?.preventDefault();

    if (isFree) {
      setChargingProcessState({
        shouldStartCharging: true,
        hasRequestedStartCharging: true,
      });
      return;
    }

    if (!email) {
      setChargingProcessState({ emailError: true });
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    elements.submit();

    setChargingProcessState({ hasRequestedStartCharging: true });

    await createPaymentMethod();

    if (!paymentIntentId) {
      await createPaymentIntent();
    } else {
      setChargingProcessState({ shouldStartCharging: true });
    }
  };

  async function createPaymentMethod() {
    if (!stripe || !elements) {
      setChargingProcessState({
        hasRequestedStartCharging: false,
      });
      return;
    }

    const pm = await stripe.createPaymentMethod({
      elements,
      params: { billing_details: { email } },
    });

    if (pm.error) {
      toast.error({
        message: t("error.payment"),
      });
      setChargingProcessState({
        hasRequestedStartCharging: false,
      });
    } else {
      setChargingProcessState({
        paymentMethodId: pm.paymentMethod.id,
      });
    }
  }

  async function createPaymentIntent() {
    try {
      const { data } = await api.post("chargingProcess/createPaymentIntent", {
        amount,
        email,
      });
      const { paymentIntentId, clientSecret: stripeClientSecret } = data;

      setChargingProcessState({
        shouldStartCharging: true,
        paymentIntentId,
        stripeClientSecret,
      });
    } catch (e: any) {
      if (e?.response?.data?.errorReason?.startsWith?.("invalid email")) {
        toast.error({
          message: t("error.email"),
        });
        setChargingProcessState({ emailError: true });
      } else {
        toast.error({
          message: t("error.payment"),
        });
      }
      setChargingProcessState({ hasRequestedStartCharging: false });
      parseClientError(e, "CREATE_PAYMENT_INTENT");
    }
  }

  const handle3DSecurePayment = useCallback(async () => {
    if (!stripe || !stripeClientSecret) {
      setChargingProcessState({ hasRequestedStartCharging: false });
      return;
    }

    const res = await stripe.handleNextAction({
      clientSecret: stripeClientSecret,
    });

    if (res.error) {
      toast.error({
        message: t("error.extendedAuthError"),
      });

      setChargingProcessState({ hasRequestedStartCharging: false });
    } else if (res.paymentIntent) {
      setChargingProcessState({
        paymentIntentId: res.paymentIntent.id,
        shouldStartCharging: true,
      });
    }
  }, [stripe, stripeClientSecret, setChargingProcessState, t]);

  useEffect(() => {
    if (shouldStartCharging) {
      startCharging().then((result) => {
        if (result === StartChargingResult.EXTENDED_AUTH_REQUIRED) {
          handle3DSecurePayment();
        }
      });
    }
  }, [shouldStartCharging, startCharging, handle3DSecurePayment]);

  useEffect(() => {
    elements?.update({ locale: i18n.language as StripeElementLocale });
  }, [elements, i18n]);

  return (
    <form
      onSubmit={onSubmitForm}
      className="flex flex-col gap-8 md:gap-9 w-full"
    >
      <div
        className={clsx("flex flex-col gap-8 md:gap-9", {
          hidden: isFree || startChargingRequested,
        })}
      >
        <EmailInput />

        <AmountInput />

        <div className="flex flex-col">
          <h3 className="font-semibold text-lg">{t("paymentHeadline")}</h3>

          {!elements && (
            <div className="self-stretch flex justify-center py-3">
              <Spinner />
            </div>
          )}

          <ExpressCheckoutElement
            className="mb-2"
            onLoadError={(e) => {
              console.error("Express checkout load error", e);
            }}
            onClick={(e) => {
              if (!email) {
                setChargingProcessState({ emailError: false });
                return;
              } else {
                e.resolve();
              }
            }}
            onConfirm={() => {
              onSubmitForm();
            }}
          />

          <PaymentElement options={{ layout: "tabs" }} />
        </div>
      </div>

      <Button
        disabled={isDisabled}
        className={clsx("justify-center", { hidden: startChargingRequested })}
      >
        {t("chargeButtonLabel")}
      </Button>

      {startChargingRequested && <ChargingStartingIndicator />}
    </form>
  );
}
